@import "~@angular/material/prebuilt-themes/indigo-pink.css";
a {
  transition: all 0.2s ease;
  cursor: pointer;
}

.loading-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease-in-out;
  background: #fff;
  opacity: 1;
  z-index: 9999;
}
.loading-container.hidden {
  opacity: 0;
  pointer-events: none;
}

.loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 250px;
  height: 20px;
  margin: auto;
}

.loader--dot {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid white;
  border-radius: 100%;
  background-color: black;
  animation-name: loader;
  animation-timing-function: ease-in-out;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

.loader--dot:first-child {
  background-color: #64be28;
  animation-delay: 0.5s;
}

.loader--dot:nth-child(2) {
  background-color: #2a5db0;
  animation-delay: 0.4s;
}

.loader--dot:nth-child(3) {
  background-color: #c80019;
  animation-delay: 0.3s;
}

.loader--dot:nth-child(4) {
  background-color: #fa9600;
  animation-delay: 0.2s;
}

.loader--dot:nth-child(5) {
  background-color: #00bedc;
  animation-delay: 0.1s;
}

.loader--dot:nth-child(6) {
  background-color: #ffdc0f;
  animation-delay: 0s;
}

.loader--text {
  position: absolute;
  top: 200%;
  right: 0;
  left: 0;
  width: 4rem;
  margin: auto;
}

.loader--text:after {
  display: block;
  margin-top: 40px;
  font-size: 24px;
  font-weight: 100;
  content: "Loading";
  animation-name: loading-text;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes loader {
  15% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(230px);
  }
  65% {
    transform: translateX(230px);
  }
  95% {
    transform: translateX(0);
  }
}
@keyframes loading-text {
  0% {
    content: "Loading";
  }
  25% {
    content: "Loading.";
  }
  50% {
    content: "Loading..";
  }
  75% {
    content: "Loading...";
  }
}
html {
  font-size: 62.5%;
  line-height: 1.15;
}

/* You can add global styles to this file, and also import other style files */
body {
  margin: 0;
  background-color: #f8f9fb;
  color: #000a12;
  font-family: Roboto, sans-serif;
  font-size: 1.4rem;
  font-feature-settings: "kern", "liga";
}

#loading-container svg {
  filter: hue-rotate(300deg) saturate(2) brightness(1.1) contrast(1.3);
}