.loading-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity .5s ease-in-out;
  background: #fff;
  opacity: 1;
  z-index: 9999;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 250px;
  height: 20px;
  margin: auto;
}

.loader--dot {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid white;
  border-radius: 100%;
  background-color: black;
  animation-name: loader;
  animation-timing-function: ease-in-out;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

.loader--dot:first-child {
  background-color: #64be28;
  animation-delay: .5s;
}

.loader--dot:nth-child(2) {
  background-color: #2a5db0;
  animation-delay: .4s;
}

.loader--dot:nth-child(3) {
  background-color: #c80019;
  animation-delay: .3s;
}

.loader--dot:nth-child(4) {
  background-color: #fa9600;
  animation-delay: .2s;
}

.loader--dot:nth-child(5) {
  background-color: #00bedc;
  animation-delay: .1s;
}

.loader--dot:nth-child(6) {
  background-color: #ffdc0f;
  animation-delay: 0s;
}

.loader--text {
  position: absolute;
  top: 200%;
  right: 0;
  left: 0;
  width: 4rem;
  margin: auto;
}

.loader--text:after {
  display: block;
  margin-top: 40px;
  font-size: 24px;
  font-weight: 100;
  content: "Loading";
  animation-name: loading-text;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes loader {

  15% {
    transform: translateX(0);
  }

  45% {
    transform: translateX(230px);
  }

  65% {
    transform: translateX(230px);
  }

  95% {
    transform: translateX(0);
  }
}

@keyframes loading-text {

  0% {
    content: "Loading";
  }

  25% {
    content: "Loading.";
  }

  50% {
    content: "Loading..";
  }

  75% {
    content: "Loading...";
  }
}